import Vue from 'vue'
import i18n from './libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment';

Vue.mixin({
    methods: { 
        toastSuccess: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-check',
                    variant:'success',
                },
            })
        },
        toastError: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-xmark',
                    variant:'danger',
                },
            })
        },
        toastWarning: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-exclamation',
                    variant:'warning',
                },
            })
        },
        toastInfo: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-info',
                    variant:'info',
                },
            })
        },
        errorHandler(error){

            let {status, data} = error.response;

            switch(status){
                case 400:
                    this.toastError('Fail',data.errors);break;
                case 422:
                    this.toastError('Fail',data.errors.join('<br>'));break;
                default:
                    this.toastError('Fail',error.message);
                    break;
            }
        },
        switchLocale(country, locale, currency) {
            localStorage.setItem('country', country.toLowerCase())
            localStorage.setItem('language', locale.toLowerCase())
            localStorage.setItem('currency', currency.toUpperCase())

            // Set currency locale
            i18n.locale = locale.toLowerCase();
        },
        resetObject: function(object) {
            Object.keys(object).forEach( (key) => {
                if( object[key] !== null && typeof object[key] == 'object'){
                     Object.keys(object[key]).forEach( (key2) => {
                        object[key][key2] = null;
                     });
                }else{
                    object[key] = null;
                }
            });
        },
        toLocalDateTime: function(datetime,format = 'YYYY-MM-DD hh:mm A'){
            if(!format)
                return datetime?moment.utc(datetime).local():'';
            else
                return datetime?moment.utc(datetime).local().format(format):'';
        },
        toLocalDateTimeFormat: function(datetime,format = 'YYYY-MM-DD HH:mm'){
            if(!format)
                return datetime?moment.utc(datetime).local():'';
            else
                return datetime?moment.utc(datetime).local().format(format):'';
        },
        toSplitDatetimeFormatInTwoRaws(datetime){
            if(datetime){
                const split = datetime.split(' ');
                return `${split[0]}<br>${split[1]}`
            }else{
                return '';
            }
        },
        toLocalDate: function(datetime,format = 'DD/MM/YYYY'){
            if(!format)
                return datetime?moment.utc(datetime).local():'';
            else
                return datetime?moment.utc(datetime).local().format(format):'';
        },
        toSplitDatetimeInTwoRaws(datetime){
            if(datetime){
                const split = datetime.split(' ');
                return `${split[0]}<br>${split[1]} ${split[2]}`
            }else{
                return '';
            }
        },
        getCountryName(country_code){
            let countryName = null;
            if (country_code){
                let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
                countryName = regionNames.of(country_code.toUpperCase());
            }
            return countryName;
        },
    },
})