import axios from "axios"
import api from '@/axios/api.js'

export default {
    namespaced: true,
    state: {
        prizes: [],
        prizePromise: null,
    },
    getters: {
        getPrizes: state => state.prizes,
    },
    mutations: {
        SET_PRIZES(state, prizes) {
            state.prizes = prizes
        },
        SET_PRIZE_PROMISE(state, promise) {
            state.prizePromise = promise
        },
    
    },
    actions: {
        fetchPrizes({ state, commit }) {
            let campaignCode = process.env.VUE_APP_CAMPAIGN_CODE;
            let url = api.info

            if (state.prizePromise) {
				return state.prizePromise
			}
            
            let prizePromise = new Promise((resolve, reject) => {
                axios.get(url,{
                    params: {
                        campaign_code: campaignCode,
                    }
                })
                .then((response) => {
                    let data = response.data.data
                    commit('SET_PRIZES', data)
                    resolve(data)
                })
                .catch(error => reject(error));
            })

            commit('SET_PRIZE_PROMISE', prizePromise)
			return prizePromise
        },
    }
}
