import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        missionReward: null,
        missionRewards: [],
    },
    getters: {
        missionRewards: (state) => {
            return state.missionRewards
        },
    },
    mutations: {
        SET_MISSION_REWARD(state, missionReward) {
            state.missionReward = missionReward
        },
        SET_MISSION_REWARDS(state, missionRewards) {
            state.missionRewards = missionRewards
        }
    },
    actions: {
        fetchMissionRewards({commit}) {
            let token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `${process.env.VUE_APP_BASE_URL}/api/v1/member/campaign/mission-reward`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        if (response.data?.data?.reward_prizes) {
                            commit('SET_MISSION_REWARDS', response.data?.data?.reward_prizes)
                        }

                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_MISSION_REWARDS', [])
                        reject(error)
                    })
            })
        },
        claimMissionReward({commit}, id) {
            let token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: `${process.env.VUE_APP_BASE_URL}/api/v1/member/campaign/mission-reward/${id}/claim`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        if (response.data) {
                            commit('SET_MISSION_REWARD', response.data)
                        }

                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_MISSION_REWARD', null)
                        reject(error)
                    })
            })
        }
    }
}