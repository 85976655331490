import axios from "@/libs/axios.js"
import ability from "@/libs/acl/ability.js"
import { initialAbility, authAbility } from '@/libs/acl/config'

export default {
    namespaced: true,
    state: {
      userData : null,
      isUserLoggedIn: false,
      winnerList: [],
      winnerListPromise: null,
      myPrizes: [],
    },
    getters: {
        userData: (state) => {
            return state.userData??JSON.parse(localStorage.getItem('userData'));
        },
        isUserLoggedIn: (state) => {
            return state.isUserLoggedIn;
        },
        getWinnerList: state => state.winnerList,
        myPrizes: (state) => {
            return state.myPrizes;
        },
    },
    mutations: {
        UPDATE_USER_DATA(state, val) {
            state.userData = val
            localStorage.setItem('userData', JSON.stringify(val))
        },
        UPDATE_IS_USER_LOGGED_IN(state, val){
            state.isUserLoggedIn = val
        },
        SET_WINNER_LIST(state, info) {
            state.winnerList = info
        },
        SET_WINNER_LIST_PROMISE(state, promise) {
            state.winnerListPromise = promise
        },
        SET_MY_PRIZES(state, value) {
            state.myPrizes = value;
        },
    },
    actions: {
        fetchUserData({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `${process.env.VUE_APP_BASE_URL}/api/v1/member/campaign/profile`,
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                }) 
                .then((response) => {
                    commit('UPDATE_USER_DATA', response.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error)
                    commit('UPDATE_IS_USER_LOGGED_IN', false);
                    commit('UPDATE_USER_DATA', null);
                    localStorage.removeItem('token')
                })
            })
        },
        updateIsUserLoggedIn({ commit },val) {

            commit('UPDATE_IS_USER_LOGGED_IN', val);

        },
        logout({ commit }) {

            commit('UPDATE_USER_DATA', null);

            localStorage.removeItem('token')

            commit('UPDATE_IS_USER_LOGGED_IN', false);

            ability.update(initialAbility)
        },
        login({ commit },val) {

            localStorage.setItem('token', val);

            commit('UPDATE_IS_USER_LOGGED_IN', true);

            ability.update(authAbility);

        },
        fetchWinnerList({ commit }) {
            let url = `${process.env.VUE_APP_BASE_URL}/api/v1/member/campaign/winner-history`

            let winnerListPromise = new Promise((resolve, reject) => {
                axios.get(url, {
                    params: {
                        campaign_code: `${process.env.VUE_APP_CAMPAIGN_CODE}`,
                        v: Math.floor(Date.now() / 1000)
                    }
                })
                .then((response) => {
                    let data = response.data
                    commit('SET_WINNER_LIST', data)
                    resolve(data)
                })
                .catch(error => reject(error));
            })
            commit('SET_WINNER_LIST_PROMISE', winnerListPromise)
			return winnerListPromise
        },
        fetchMyPrizes({ commit }) {
            const token = localStorage.getItem('token');

            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `${process.env.VUE_APP_BASE_URL}/api/v1/member/campaign/history`,
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        commit('SET_MY_PRIZES', response.data.data);
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_MY_PRIZES', []);
                        reject(error)
                    })
            })
        }
    },
  }
