import { device } from "@/libs/vue-mobile-detection";
export default {
    decorTree: require('@/assets/images/' + device() + '/main/decor-tree.png'),
    spinTitle: require('@/assets/images/' + device() + '/main/spin-title.png'),
    point: require('@/assets/images/' + device() + '/main/select-point.png'),
    segments: require('@/assets/images/' + device() + '/main/segments-my.png'),
    wheelBoard: require('@/assets/images/' + device() + '/main/wheel-board.png'),
    decorSnowflakesLeft: require('@/assets/images/' + device() + '/main/decor-snowflakes-left.png'),
    decorSnowflakesRight: require('@/assets/images/' + device() + '/main/decor-snowflakes-right.png'),
    logoWithShadow: require('@/assets/images/' + device() + '/logo/inz9-logo-color-dropshadow.png'),
    favIcon: require('@/assets/images/' + device() + '/logo/inz9-favicon.png'),
    logo: require('@/assets/images/' + device() + '/logo/logo.png'),
    username: require('@/assets/images/desktop/icon/my-account-icon.png'),
    headerBack: require('@/assets/images/mobile/icon/back-icon.png'),
    giftOne:require('@/assets/images/' + device() + '/prize/gift-1.png'),
    giftTwo:require('@/assets/images/' + device() + '/prize/gift-2.png'),
    giftThree:require('@/assets/images/' + device() + '/prize/gift-3.png'),
    giftFour:require('@/assets/images/' + device() + '/prize/gift-4.png'),
    giftFive:require('@/assets/images/' + device() + '/prize/gift-5.png'),
    giftSix:require('@/assets/images/' + device() + '/prize/gift-6.png'),
    giftSeven:require('@/assets/images/' + device() + '/prize/gift-7.png'),
    giftEight:require('@/assets/images/' + device() + '/prize/gift-8.png'),

    tanglong:require('@/assets/images/' + device() + '/cny-2025/home-page/lantern-image.webp'),
    btnSpin:require('@/assets/images/' + device() + '/cny-2025/button/button-spin.webp'),
    btnSpinMobile:require('@/assets/images/mobile/cny-2025/button/mobile-wheel-button.webp'),
    btnArrow:require('@/assets/images/' + device() + '/cny-2025/home-page/arrow.webp'),
    crystal:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/crystal.webp'),
    exclamation:require('@/assets/images/' + device() + '/cny-2025/home-page/info-detail-button.webp'),

    prizeIphone:require('@/assets/images/' + device() + '/cny-2025/crystal-gift/iphone.webp'),
    prizeIpad:require('@/assets/images/' + device() + '/cny-2025/crystal-gift/ipad.webp'),
    prizeMassage:require('@/assets/images/' + device() + '/cny-2025/crystal-gift/massage.webp'),
    prizeSoundbar:require('@/assets/images/' + device() + '/cny-2025/crystal-gift/soundbar.webp'),
    prizeGoldBar:require('@/assets/images/' + device() + '/cny-2025/crystal-gift/gold-bar.webp'),
    prizeCash:require('@/assets/images/' + device() + '/cny-2025/crystal-gift/cash.webp'),
    iconCrystal:require('@/assets/images/' + device() + '/cny-2025/home-page/icon-crystal.webp'),

    progressIcon:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/lucky-icon.webp'),
    progressLabel:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/label-progress.webp'),
    progressOne:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/prize-step-1.webp'),
    progressTwo:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/prize-step-2.webp'),
    progressThree:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/prize-step-3.webp'),
    progressFour:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/crystal.webp'),
    progressFive:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/crystal-step-2.webp'),
    progressSix:require('@/assets/images/' + device() + '/cny-2025/lucky-bar/prize-progress-icon.webp'),

    iconTick:require('@/assets/images/' + device() + '/cny-2025/button/tick.webp'),
    caishen:require('@/assets/images/' + device() + '/cny-2025/home-page/caishen-image.webp'),
    cloudLeft:require('@/assets/images/' + device() + '/cny-2025/home-page/cloud-design-left.webp'),
    cloudRight:require('@/assets/images/' + device() + '/cny-2025/home-page/cloud-design-right.webp'),

    leaderboardTitle:require('@/assets/images/' + device() + '/cny-2025/leaderboard/leaderboard-title.webp'),
    flowerLeft:require('@/assets/images/' + device() + '/cny-2025/leaderboard/flower-left.webp'),
    flowerRight:require('@/assets/images/' + device() + '/cny-2025/leaderboard/flower-right.webp'),

    btnPrizePool:require('@/assets/images/' + device() + '/cny-2025/button/prize-pool-main-icon.webp'),
    btnTnc:require('@/assets/images/' + device() + '/cny-2025/button/tnc-main-icon.webp'),
    btnDeposit:require('@/assets/images/' + device() + '/cny-2025/button/deposit-main-icon.webp'),
    btnHistory:require('@/assets/images/' + device() + '/cny-2025/button/history-main-icon.webp'),

    iconInformation:require('@/assets/images/' + device() + '/cny-2025/pop-up/information-icon.webp'),
    iconTopLeftFlower:require('@/assets/images/' + device() + '/cny-2025/pop-up/top-left-element.webp'),
    iconCenterMoney:require('@/assets/images/' + device() + '/cny-2025/pop-up/center-element.webp'),
    iconCenterCoin:require('@/assets/images/' + device() + '/cny-2025/pop-up/coin-element.webp'),
    btnClose:require('@/assets/images/' + device() + '/cny-2025/pop-up/pop-up-button.webp'),
    iconClose:require('@/assets/images/' + device() + '/cny-2025/pop-up/close-button.webp'),
    iconBottomRightFlower:require('@/assets/images/' + device() + '/cny-2025/pop-up/bottom-right-element.webp'),

    wheelBoardCny:require('@/assets/images/' + device() + '/cny-2025/wheel/wheel-frame.webp'),
    segmentsCny:require('@/assets/images/' + device() + '/cny-2025/wheel/wheel-content-myr.webp'),
    pointCny:require('@/assets/images/' + device() + '/cny-2025/wheel/wheel-center.webp'),
    pinCny:require('@/assets/images/' + device() + '/cny-2025/wheel/wheel-arrow.webp'),
    coinOne:require('@/assets/images/' + device() + '/cny-2025/wheel/coin-one.webp'),
    coinTwo:require('@/assets/images/' + device() + '/cny-2025/wheel/coin-two.webp'),
    coinThree:require('@/assets/images/' + device() + '/cny-2025/wheel/coin-three.webp'),
    coinFour:require('@/assets/images/' + device() + '/cny-2025/wheel/coin-four.webp'),

    homepageBg:require('@/assets/images/' + device() + '/cny-2025/home-page/background.webp'),

    bmm: require('@/assets/images/'+device()+'/lisence/bmm-logo.webp'),
    itechLabs: require('@/assets/images/'+device()+'/lisence/itech-labs-logo.webp'),
    gameLicense: require('@/assets/images/'+device()+'/lisence/gaming-license.webp'),
    gamingInternational: require('@/assets/images/'+device()+'/lisence/gaming-international-logo.webp'),
    payoutReviewInternational: require('@/assets/images/'+device()+'/lisence/payout-review-international-logo.webp'),
    godaddyVerified: require('@/assets/images/'+device()+'/lisence/godaddy-verified-logo.webp'),

    eighteenPlus: require('@/assets/images/desktop/lisence/18-plus-logo.webp'),
    gameCare: require('@/assets/images/desktop/lisence/game-care-logo.webp'),
    beGambleAware: require('@/assets/images/desktop/lisence/be-gamble-aware-logo.webp'),
    iovation: require('@/assets/images/'+device()+'/lisence/iovation-logo.webp'),
    youtube: require('@/assets/images/'+device()+'/lisence/youtube-logo.webp'),
    facebook: require('@/assets/images/'+device()+'/lisence/facebook-logo.webp'),

    iconCoinTopLeftPopUp: require('@/assets/images/'+device()+'/cny-2025/pop-up/pop-up-element.webp'),
    iconCoinBottomRightPopUp: require('@/assets/images/'+device()+'/cny-2025/pop-up/pop-up-element-bottom.webp'),

    prizeMyrFirst: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/prize-myr-88.webp'),
    prizeMyrSecond: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/prize-myr-188.webp'),
    prizeMyrThird: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/prize-myr-288.webp'),
    prizeSgdFirst: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/prize-sgd-88.webp'),
    prizeSgdSecond: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/prize-sgd-188.webp'),
    prizeSgdThird: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/prize-sgd-288.webp'),
    angPaoBackend: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/frame-end.webp'),
    angPaoFrontend: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/frame-front.webp'),
    angPaoGif: require('@/assets/images/'+device()+'/cny-2025/gif/angpao/angpaoGif.gif'),
    crystalGif: require('@/assets/images/'+device()+'/cny-2025/gif/crystal/crystal-gif.gif'),

    bitcoin: require('@/assets/images/mobile/lisence/bitcoin-logo.webp'),
    eeziepay: require('@/assets/images/mobile/lisence/eezie-pay-logo.webp'),
    eth: require('@/assets/images/mobile/lisence/eth-logo.webp'),
    help2pay: require('@/assets/images/mobile/lisence/help2pay-logo.webp'),
    touchngo: require('@/assets/images/mobile/lisence/touchngo-logo.webp'),
    usdt: require('@/assets/images/mobile/lisence/usdt-logo.webp'),

    pinCnyDesktop: require('@/assets/images/desktop/cny-2025/wheel/wheel-arrow-image-cropped.webp'),
    btnSpinEn: require('@/assets/images/'+device()+'/cny-2025/button/button-spin-eng.webp'),
    btnSpinMobileEn: require('@/assets/images/'+device()+'/cny-2025/button/mobile-wheel-button-eng.webp'),
    leaderboardTitleEn:require('@/assets/images/' + device() + '/cny-2025/leaderboard/leaderboard-title-eng.webp'),
    crystalTextZh:require('@/assets/images/' + device() + '/cny-2025/home-page/title-font.webp'),
    crystalTextEn:require('@/assets/images/' + device() + '/cny-2025/home-page/title-font-eng.webp'),
    crystalTextMs:require('@/assets/images/' + device() + '/cny-2025/home-page/title-font-ms.webp'),
    leaderboardTitleMs: require('@/assets/images/'+device()+'/cny-2025/leaderboard/leaderboard-title-ms.webp'),

}
